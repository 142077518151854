import Vue from 'vue'
import VueRouter from 'vue-router'

import { apiUrl, employeeApiUrl } from '@/constants/config'
import store from '@/store'
import axios from 'axios'

import partnerRoutes from './partner/index'
import customerRoutes from './customer/index'

Vue.use(VueRouter)

const platform = process.env.VUE_APP_PLATFORM || 'partner'
const routes = platform === 'partner' ? partnerRoutes : customerRoutes

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  if (platform === 'partner') {
    // check if user token is ACTIVE and LOGOUT if not
    axios
      .get(`${apiUrl}wallet`, {
        headers: {
          Authorization: `Bearer ${store.state.user.currentUser?.token}`,
        },
      })
      .catch(error => {
        const user = localStorage.getItem('user')

        if (!user) {
          return
        }

        const { userType } = JSON.parse(localStorage.getItem('user'))

        const url = userType === 'employee' ? `${employeeApiUrl}logout` : `${apiUrl}logout`

        if (error.response.status === 401) {
          axios({
            url,
            data: {},
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${store.state.user.currentUser?.token}`,
            },
          })
            .then(() => {
              localStorage.removeItem('user')
              store.state.user.currentUser = null
            })
            .catch(() => {
              localStorage.removeItem('user')
              store.state.user.currentUser = null
            })
        }
      })
  }
})

export default router
