export default [
  {
    path: '/cancelled',
    name: 'cancelled',
    component: () => import('@/views/customer/Cancelled.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/',
    name: 'daas',
    component: () => import('@/views/customer/Daas.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import('@/views/customer/Invoice.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/invoice/:ref',
    name: 'invoice-ref',
    component: () => import('@/views/customer/Invoice.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/:slug/:ref',
    name: 'daas-ref',
    component: () => import('@/views/customer/Daas.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/:slug',
    name: 'self-daas',
    component: () => import('@/views/customer/SelfDaas.vue'),
    meta: {
      layout: 'full',
    },
  },
]
