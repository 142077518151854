import AuthRequired from '../authRequired'

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    beforeEnter: AuthRequired,
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dispatches',
    name: 'dispatches',
    component: () => import('@/views/Dispatches.vue'),
    beforeEnter: AuthRequired,
    meta: {
      pageTitle: 'Dispatches',
      breadcrumb: [
        {
          text: 'Dispatches',
          active: true,
        },
      ],
    },
  },
  {
    path: '/daas',
    name: 'daas',
    component: () => import('@/views/Daas.vue'),
    beforeEnter: AuthRequired,
    meta: {
      pageTitle: 'DAAS',
      breadcrumb: [
        {
          text: 'DAAS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/riders',
    name: 'riders',
    component: () => import('@/views/Riders.vue'),
    beforeEnter: AuthRequired,
    meta: {
      pageTitle: 'Riders',
      breadcrumb: [
        {
          text: 'Riders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employees',
    name: 'employees',
    component: () => import('@/views/Employees.vue'),
    beforeEnter: AuthRequired,
    meta: {
      pageTitle: 'Employees',
      breadcrumb: [
        {
          text: 'Employees',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payouts',
    name: 'payouts',
    component: () => import('@/views/Payouts.vue'),
    beforeEnter: AuthRequired,
    meta: {
      pageTitle: 'Payouts',
      breadcrumb: [
        {
          text: 'Payouts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import('@/views/Accounts.vue'),
    beforeEnter: AuthRequired,
    meta: {
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings.vue'),
    beforeEnter: AuthRequired,
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/verify-account',
    name: 'verify-account',
    component: () => import('@/views/VerifyAccount.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/create-daas',
    name: 'create-daas',
    beforeEnter: AuthRequired,
    component: () => import('@/views/CreateDaas.vue'),
    meta: {
      pageTitle: 'Create Daas',
      breadcrumb: [
        {
          text: 'Create Daas',
          active: false,
        },
      ],
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
